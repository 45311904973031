import { useEffect, useState, useMemo, useContext } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseModel from "../base/BaseModel";
import useWindowSize from "../hooks/useWindowSize";
import { breakpoints } from "../constants/breakpoints";
import { disableBodyScroll } from "../base/baseHelper";
import { useTheme } from "../base/ThemeProvider";
import DialogModel from "./dialogMoel";
import useHandler from "../hooks/useHandler";
import useResizeObserver from "../hooks/useResizeObserver";

const useAppModel = () => {
  const { ref: headerRef, height: headerHeight } = useResizeObserver(0);
  const { ref: footerRef, height: footerHeight } = useResizeObserver(0);

  const { toggleDialog } = useContext(DialogModel.Context);

  const { ErrorHandler } = useHandler(toggleDialog);

  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { width } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchQuery = useMemo(
    () => Object.fromEntries([...searchParams]),
    [searchParams]
  );

  const [version, setVersion] = useState("desktop");
  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(false);

  const { ipad, desktop } = breakpoints;

  function toggleMenu(state) {
    disableBodyScroll(state);
    setShowMenu(state);
  }

  function changeLoading(e) {
    setLoading(e);
  }

  function changeSearchParams(e) {
    setSearchParams(e);
  }

  useEffect(() => {
    if (width <= desktop && width >= ipad) {
      return setVersion("ipad");
    }
    if (width < ipad) {
      return setVersion("mobile");
    }
    return setVersion("desktop");
  }, [width]);

  return {
    t,
    i18n,
    navigate,
    theme,
    location,
    searchQuery,
    headerRef,
    footerRef,

    headerHeight,
    footerHeight,
    version,
    showMenu,
    loading,

    toggleMenu,
    changeLoading,
    changeSearchParams,
    ErrorHandler,
    toggleDialog,
  };
};

const AppModel = BaseModel(useAppModel, "AppModel");

export { useAppModel };
export default AppModel;
