import styled from "styled-components";
import { devices } from "../../constants/breakpoints";

export const BasicButton = styled.button`
  padding: 10px 0px;
  border-radius: 40px;
  font-size: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  font-weight: bold;
  text-wrap: nowrap;
  max-width: 250px;
  width: 100%;
  align-self: center;
  height: 55px;

  ${({ tablet }) => tablet};

  img {
    height: 30px;
    margin-right: 10px;
  }

  @media ${devices.mobile} {
    ${({ mobile }) => mobile}

    img {
      height: 35px;
    }
  }

  &:disabled {
    opacity: 0.4;
    cursor: none;
  }
`;

export const SubmitButton = styled(BasicButton)`
  background-color: ${({ theme }) => theme.primary};

  @media ${devices.mobile} {
    ${({ mobile }) => mobile}
    margin-top: ${({ passworderrors }) =>
      passworderrors === undefined ? "20px" : "0px"};
  }
`;

export const TransparentButton = styled(BasicButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary};
  text-decoration: underline;
  padding: 0px;
`;

export const PrimaryButton = styled(BasicButton)`
  background-color: ${({ theme }) => theme.primary};
`;

export const OutlineButton = styled(BasicButton)`
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.primary};
  border: ${({ theme }) => `2px solid ${theme.primary}`};
`;
