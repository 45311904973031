import { isArray } from "lodash";
import { useTranslation } from "react-i18next";

export default function useHandler(toggleDialog) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  function getErrMsg(msg) {
    if (!msg) {
      return t("error.unknow");
    }

    if (typeof msg === "string") {
      return msg;
    }

    const errors = msg?.error?.errors || [];

    const errorStringArray =
      errors && isArray(errors)
        ? errors.map(
            ({ message, errorCode }) => `${message}(${errorCode})<br/>`
          )
        : [];

    const errorString =
      errorStringArray.length === 0
        ? ""
        : errorStringArray.toString().replace(/[,]/g, "");

    if (errorString.includes("100001")) {
      return t("error.passwordError");
    }

    if (errorString.includes("115002")) {
      return t("error.currentPasswordError");
    }

    return errorString || t("error.unknow");
  }

  function ErrorHandler(err, props) {
    const type = "error";

    const message = getErrMsg(err);

    toggleDialog({ ...props, title: message, type });
  }

  return {
    ErrorHandler,
  };
}
