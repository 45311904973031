import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "./base/ThemeProvider";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import DEFAULT_THEME from "./constants/theme";
import i18n from "./i18n";

const root = createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <ThemeProvider defaultTheme={DEFAULT_THEME}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </I18nextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
