export const HOME_PATH = "/";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signUp";
export const FORGET_PASSWORD_PATH = "/forget-password";
export const RESET_PASSWORD_PATH = "/reset-password";
export const REWRADS_PATH = "/my-rewards";
export const CUPON_DETAIL_PATH = "/cupon-detail";
export const NFT_DETAIL_PATH = "/nft-detail";
export const GAME_PATH = "/game";
export const PROFILE_PATH = "/profile";
export const CHANGEPASSWORD_PATH = "/changePassword";
