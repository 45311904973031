import React from "react";
// import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import SetThemeProvider, { useSetTheme } from "./SetThemeProvider";

function LibThemeProviders({ children }) {
  const { theme } = useSetTheme();
  return (
    // <MuiThemeProvider theme={theme}>
    <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    // </MuiThemeProvider>
  );
}

function ThemeProvider({ defaultTheme, children }) {
  return (
    <SetThemeProvider defaultTheme={defaultTheme}>
      <LibThemeProviders>{children}</LibThemeProviders>
    </SetThemeProvider>
  );
}

export default ThemeProvider;
