import styled, { keyframes } from "styled-components";

const rotation = keyframes`
  0% { transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
`;

export const Container = styled.div``;

export const Spinner = styled.div`
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotation} 1s linear infinite;
  margin-left: 10px;
`;
