import React, { useContext } from "react";
import { FooterContainer, FooterTextRow, MemberButton } from "./Layout.styles";
import { FlexDiv, StyledP, StyledSpan } from "../../base/BaseStyle";
import icon from "../../assets/images/whiteIcon.png";
import Button from "../Button";
import AppModel from "../../models/appModel";
import { SIGNUP_PATH } from "../../constants/paths";
import UserModel from "../../models/userModel";

export default function Footer() {
  const { navigate, footerRef } = useContext(AppModel.Context);
  const { token } = useContext(UserModel.Context);
  return (
    <FooterContainer ref={footerRef}>
      <FooterTextRow>
        <StyledSpan color="white" fontSize="20" mobile={{ fontSize: 24 }}>
          成為Chaingate會員
        </StyledSpan>

        <FlexDiv>
          <StyledSpan
            color="white"
            fontSize="20"
            margin="0px 0px 0px 10px"
            mobile={{ fontSize: 24, margin: "0px" }}
          >
            尊享
          </StyledSpan>
          <StyledSpan
            color="secondary"
            fontSize="20"
            mobile={{ fontSize: 24 }}
            fontWeight="bold"
          >
            無盡優惠
          </StyledSpan>
          <StyledSpan color="white" fontSize="20" mobile={{ fontSize: 24 }}>
            !
          </StyledSpan>
        </FlexDiv>

        {!token && (
          <Button
            type="button"
            mobile={{ marginTop: 20, fontSize: 24 }}
            onClick={() => {
              navigate(SIGNUP_PATH);
            }}
          >
            <img src={icon} alt="" />
            立即登記
          </Button>
        )}
      </FooterTextRow>

      <FlexDiv style={{ marginTop: 20 }}>
        <StyledP color="white" fontSize="15">
          條款及細則
        </StyledP>
        <StyledP color="white" margin="0px 5px" fontSize="15">
          | 私隱政策
        </StyledP>
        <StyledP color="white" margin="0px 5px" fontSize="15">
          聯絡我們 : xxxx xxxx
        </StyledP>
      </FlexDiv>

      <StyledP color="white" fontSize="12">
        Copyright© owned by Chaingate
      </StyledP>
    </FooterContainer>
  );
}
