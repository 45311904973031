import styled from "styled-components";
import { devices } from "../../constants/breakpoints";

export const StyledDialog = styled.dialog`
  border-radius: 10px;
  border: ${({ theme, icon }) =>
    icon === "error"
      ? `2px solid ${theme.danger}`
      : `2px solid ${theme.primary}`};
  width: 400px;
  overflow: visible;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media ${devices.mobile} {
    width: 80%;
  }
`;

export const BasicContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
  padding-top: ${({ icon }) => icon && "80px"};
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.primary};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.primary};
  font-size: 15px;
  text-align: center;
  white-space: pre;
`;

export const Remark = styled(Text)`
  color: ${({ theme }) => theme.danger};
`;

export const IconDiv = styled.div`
  height: 140px;
  width: 140px;
  border-radius: 50%;
  background-color: #f5f3fc;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  top: -80px;

  img {
    height: 90px;
    width: auto;
  }
`;

export const ErrorIconDiv = styled(IconDiv)`
  background-color: transparent;
  box-shadow: none;
  border-radius: 0%;
  height: auto;
  width: auto;
  top: -150px;

  img {
    height: auto;
    width: 250px;
  }
`;
