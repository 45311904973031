export default {
  menu: {
    ownership: "我的獎賞",
    about: "關於Chaingate",
    character: "角色介紹",
    nft: "NFT畫廊",
    story: "故事簡介",
    tnc: "條款及細則",
    privacyPolicy: "私隱政策",
  },

  login: {
    title: "登入",
  },
  signUp: {
    title: "成為會員",
  },
};
