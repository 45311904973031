import React, { createContext, useContext, useMemo, useState } from "react";

export const SetThemeContext = createContext(null);

function SetThemeProvider({ defaultTheme, children }) {
  const [theme, setTheme] = useState(defaultTheme);

  const contextValue = useMemo(
    () => ({
      theme,
      setTheme,
    }),
    [theme]
  );

  return (
    <SetThemeContext.Provider value={contextValue}>
      {children}
    </SetThemeContext.Provider>
  );
}

export default SetThemeProvider;

export function useSetTheme() {
  const value = useContext(SetThemeContext);

  if (!value) {
    throw new Error(`SetThemeContext does not exists`);
  }

  return value;
}

export function useTheme() {
  const { theme } = useSetTheme();
  return theme;
}
