import styled from "styled-components";

const Container = styled.div`
  font-size: 50px;
  font-weight: 600;
  height: 100%;
  line-height: 100%;
  text-align: center;
  vertical-align: middle;
`;

export default Container;
