import React, { useContext, useEffect, useRef } from "react";
import DialogModel from "../../../../models/dialogMoel";
import {
  BasicContainer,
  Title,
  Text,
  ErrorIconDiv,
  Remark,
  StyledDialog,
} from "../../style";
import Button from "../../../Button";
import ErrorIcon from "../../../../assets/images/Error.png";

export default function ErrorDialog({ data = {} }) {
  const ref = useRef();
  const { t, toggleDialog } = useContext(DialogModel.Context);

  const { props = {}, id = "" } = data || {};

  const {
    title = "",
    text = "",
    confirmButtonText = t("button.confirm"),
    titleStyle,
    textStyle,
    containerStyle,
    remark,
    remarkStyle,
  } = props || {};

  useEffect(() => {
    const currentModal = ref?.current;

    if (currentModal?.open) {
      return;
    }

    if (currentModal) {
      ref.current.showModal();
    }
  }, []);

  return (
    <StyledDialog ref={ref} icon="error">
      <BasicContainer icon="true" style={containerStyle}>
        <ErrorIconDiv>
          <img src={ErrorIcon} alt="" />
        </ErrorIconDiv>

        <Title style={titleStyle} dangerouslySetInnerHTML={{ __html: title }} />
        <Text style={textStyle}>{text}</Text>
        {remark && <Remark style={remarkStyle}>{remark}</Remark>}
        <Button
          style={{ marginTop: 10 }}
          onClick={() => {
            toggleDialog({ open: false }, id);
          }}
        >
          {confirmButtonText}
        </Button>
      </BasicContainer>
    </StyledDialog>
  );
}
