// import { createTheme } from "../base/ThemeProvider";

const DEFAULT_THEME = {
  primary: "#5550E8",
  secondary: "#EA7500",
  primaryText: "#181560",
  blackText: "#000",
  orangeText: "#FF6B36",
  brownText: "#544635",
  grayText: "#AFAFAF",
  darkGrayText: "#707070",
  white: "#fff",
  inputBorder: "#E3E2EC",
  inputText: "#CDCCDE",
  danger: "#EC595C",
  darkPrimary: "#241F55",
};

export default DEFAULT_THEME;
