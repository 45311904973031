import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { BodyContainer } from "./Layout.styles";
import Header from "./Header";
// import SlideMenu from "./SlideMenu";
import AppModel from "../../models/appModel";
import Footer from "./Footer";
import Menu from "./Menu";
import Dialog from "../Dialog";
import LoadingView from "../LoadingView";

export default function Layout() {
  const { version, loading, headerHeight, footerHeight, showMenu } = useContext(
    AppModel.Context
  );

  return (
    <>
      {version !== "desktop" && <Header />}
      <Menu />
      <BodyContainer
        $headerHeight={headerHeight}
        $footerHeight={footerHeight}
        $showMenu={showMenu}
      >
        <Outlet />
      </BodyContainer>

      {version !== "desktop" && <Footer />}
      <Dialog />
      {loading && <LoadingView />}
    </>
  );
}
