import { useContext, useCallback, useState } from "react";
import BaseModel from "../base/BaseModel";
import AppModel from "./appModel";
import { APIRequestGenerator } from "../base/BaseActions";
import APIHelper from "../base/APIHelper";
import { HOME_PATH } from "../constants/paths";

const module = "user";

const useUserModel = (savedModelInstance = {}) => {
  const { t, i18n, toggleDialog, navigate, toggleMenu } = useContext(
    AppModel.Context
  );

  const [user, setUser] = useState(savedModelInstance?.user);
  const [token, setToken] = useState(savedModelInstance?.token);

  function logout() {
    toggleDialog({
      title: "你要登出嗎？",
      confirmButtonText: t("button.logout"),
      onConfirm: () => {
        setUser({});
        setToken(null);
        navigate(HOME_PATH);
        toggleMenu(false);
      },
    });
  }

  async function register(data) {
    delete data.confirmPassword;

    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/mintSite/register`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function sendSMS(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/mintSite/sms`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function login(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/auth/mintsite/local`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      const { data: { token: responseToken } = {} } = response || {};
      setToken(responseToken);

      return Promise.resolve(responseToken);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getMe(inputToken) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/me`,
      null,
      inputToken || token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      const { data = {} } = response || {};
      const { user: responseUser } = data || {};

      setUser(responseUser);

      return Promise.resolve(responseUser);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function forgotPassword(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/mintSite/forgot`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function resetPassword(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/reset`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function changePassword(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/me/password`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getCoupons(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/me/coupon`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getNFT(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/me/product`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  const onSaveInstanceState = useCallback(
    () => ({
      token,
      user,
    }),
    [token, user]
  );

  return {
    token,
    user,

    onSaveInstanceState,

    register,
    sendSMS,
    login,
    forgotPassword,
    getMe,
    logout,
    getCoupons,
    getNFT,
    resetPassword,
    changePassword,
  };
};

const UserModel = BaseModel(useUserModel, "UserModel");

export { useUserModel };
export default UserModel;
