import styled from "styled-components";
// import { Link } from "react-router-dom";
import { animated } from "@react-spring/web";
import { devices } from "../../../constants/breakpoints";
import menuBg from "../../../assets/images/menuBg.png";

export const Container = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 15;
  box-sizing: border-box;
  padding-top: ${({ $headerHeight }) => `${$headerHeight}px`};

  @media ${devices.mobile} {
    // padding: 0px;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: stretch;
  margin-top: 50px;
  z-index: 1;
  width: 100%;

  /* button {
    z-index: 1;
  } */

  @media ${devices.mobile} {
    margin-top: 20px;
  }
`;

export const IconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: 50px;

  @media ${devices.mobile} {
    margin-top: 20px;
  }
`;

export const BgImg = styled.img`
  position: absolute;
  top: 0px;
  width: 120%;
  height: 450px;

  @media ${devices.mobile} {
    height: 300px;
    width: 120%;
  }
`;

export const TopDiv = styled.div`
  /* background-image: url(${menuBg}); */
  width: 100%;
  display: flex;
  justify-content: center;
  /* height: 450px; */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  align-items: flex-start;

  @media ${devices.mobile} {
    /* height: 300px; */
  }
`;

export const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  justify-content: center;
  height: 100%;

  @media ${devices.mobile} {
    gap: 0;
    margin-top: 20px;
    justify-content: space-around;
  }
`;

export const StyledLink = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.primary};
  font-size: 25px;
  z-index: 1;

  @media ${devices.mobile} {
    font-size: 20px;
  }
`;

export const LionImg = styled.img`
  /* position: absolute; */
  /* bottom: 0; */
  width: 30%;
  height: auto;
  /* left: 0;
  right: 0; */
  /* margin: auto; */

  @media ${devices.mobile} {
    width: 50%;
  }
`;

export const ProfileIcon = styled.div`
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 10px solid #f5f3fc;
  border-radius: 50%;
  position: relative;
`;

export const DefaultPrifleIcon = styled.img`
  width: 100%;
  height: 100%;
`;

export const LogoutButton = styled.button`
  height: 50px;
  width: 50px;
  position: absolute;
  bottom: -10px;
  right: -10px;
  background-color: #f5f3fc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 30px;
    height: auto;
    margin-top: 5px;
    margin-left: 5px;
  }
`;

export const DisplayNameCol = styled.div`
  margin-left: 20px;
`;
