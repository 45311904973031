import { useContext } from "react";
import BaseModel from "../base/BaseModel";
import BaseActions, { APIRequestGenerator } from "../base/BaseActions";
import APIHelper from "../base/APIHelper";
import UserModel from "./userModel";

const module = "product";

const useProductModel = (savedModelInstance = {}) => {
  const { token } = useContext(UserModel.Context);

  const { getOne, getList, updateOne, createOne } = BaseActions(module, {
    token,
  });

  async function verifyOwnership(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/ownership`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getOne,
    getList,
    updateOne,
    createOne,
    verifyOwnership,
  };
};

const ProductModel = BaseModel(useProductModel, "ProductModel");

export { useProductModel };
export default ProductModel;
