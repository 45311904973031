import React, { useContext, useEffect, useRef } from "react";
import DialogModel from "../../../../models/dialogMoel";
import {
  BasicContainer,
  Title,
  Text,
  IconDiv,
  Remark,
  StyledDialog,
} from "../../style";
import Button from "../../../Button";
import getImage from "../GetImage";

export default function BaseDialog({ data = {} }) {
  const ref = useRef();
  const { t, toggleDialog } = useContext(DialogModel.Context);

  const { props = {}, id = "" } = data || {};

  const {
    title = "",
    text = "",
    confirmButtonText = t("button.confirm"),
    onConfirm = () => Promise.resolve(true),
    cancelText = t("button.cancel"),
    onCancel = () => Promise.resolve(true),
    showCancelButton = true,
    titleStyle,
    textStyle,
    containerStyle,
    icon,
    remark,
    remarkStyle,
    closeAfterConfirm = true,
  } = props || {};

  async function asyncConfirm() {
    try {
      await onConfirm();
      if (!closeAfterConfirm) {
        return;
      }
      toggleDialog({ open: false }, id);
    } catch (error) {
      //
    }
  }

  async function asyncCancel() {
    try {
      await onCancel();
      toggleDialog({ open: false }, id);
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    const currentModal = ref?.current;

    if (currentModal?.open) {
      return;
    }

    if (currentModal) {
      ref.current.showModal();
    }
  }, []);

  return (
    <StyledDialog ref={ref} icon={icon}>
      <BasicContainer icon={icon} style={containerStyle}>
        {icon && (
          <IconDiv icon={icon}>
            <img src={getImage(icon)} alt="" />
          </IconDiv>
        )}

        <Title style={titleStyle}>{title}</Title>
        <Text style={textStyle}>{text}</Text>
        {remark && <Remark style={remarkStyle}>{remark}</Remark>}
        <Button style={{ marginTop: 10 }} onClick={asyncConfirm}>
          {confirmButtonText}
        </Button>

        {showCancelButton && (
          <Button variant="transparent" onClick={asyncCancel}>
            {cancelText}
          </Button>
        )}
      </BasicContainer>
    </StyledDialog>
  );
}
