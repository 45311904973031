import { lazy } from "react";
import {
  HOME_PATH,
  SIGNUP_PATH,
  LOGIN_PATH,
  FORGET_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  REWRADS_PATH,
  CUPON_DETAIL_PATH,
  NFT_DETAIL_PATH,
  GAME_PATH,
  PROFILE_PATH,
  CHANGEPASSWORD_PATH,
} from "./paths";

const Home = lazy(() => import("../views/Home"));
const SignUp = lazy(() => import("../views/SignUp"));
const Login = lazy(() => import("../views/Login"));
const ForgetPassword = lazy(() => import("../views/ForgetPassword"));
const ResetPassword = lazy(() => import("../views/ResetPassword"));
const MyRewards = lazy(() => import("../views/MyRewards"));
const CuponDetail = lazy(() => import("../views/CuponDetail"));
const NFTDetail = lazy(() => import("../views/NFTDetail"));
const Game = lazy(() => import("../views/Game"));
const Profile = lazy(() => import("../views/Profile"));
const ChangePassword = lazy(() => import("../views/ChangePassword"));

const ROUTES = [
  {
    path: HOME_PATH,
    component: Home,
  },
  {
    path: SIGNUP_PATH,
    component: SignUp,
  },
  {
    path: LOGIN_PATH,
    component: Login,
  },
  {
    path: FORGET_PASSWORD_PATH,
    component: ForgetPassword,
  },
  {
    path: RESET_PASSWORD_PATH,
    component: ResetPassword,
  },
  {
    path: REWRADS_PATH,
    component: MyRewards,
  },
  {
    path: CUPON_DETAIL_PATH,
    component: CuponDetail,
  },
  {
    path: NFT_DETAIL_PATH,
    component: NFTDetail,
  },
  {
    path: GAME_PATH,
    component: Game,
  },
  {
    path: PROFILE_PATH,
    component: Profile,
  },
  {
    path: CHANGEPASSWORD_PATH,
    component: ChangePassword,
  },
];

export default ROUTES;
