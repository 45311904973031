import { useContext } from "react";
import BaseModel from "../base/BaseModel";
import BaseActions, { APIRequestGenerator } from "../base/BaseActions";
import UserModel from "./userModel";

const module = "coupon";

const useCouponModel = (savedModelInstance = {}) => {
  const { token } = useContext(UserModel.Context);

  const { getOne, getList, updateOne, createOne } = BaseActions(module, {
    token,
  });

  return {
    getOne,
    getList,
    updateOne,
    createOne,
  };
};

const CouponModel = BaseModel(useCouponModel, "CouponModel");

export { useCouponModel };
export default CouponModel;
