import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import BaseModel from "../base/BaseModel";
import { disableBodyScroll } from "../base/baseHelper";

const useDialogModel = () => {
  const { t, i18n } = useTranslation();

  const [list, setList] = useState([]);

  function toggleDialog(e, id) {
    if (!id) {
      disableBodyScroll(true);
      setList((prev) => [
        ...prev,
        { props: e, id: moment().toISOString(), open: true },
      ]);
      return;
    }

    setList((prev) => {
      const filteredArray = prev.filter((v) => v.id !== id);
      if (filteredArray.length === 0) {
        disableBodyScroll(false);
      }
      return filteredArray;
    });
  }

  return {
    t,
    i18n,

    toggleDialog,

    list,
  };
};

const DialogModel = BaseModel(useDialogModel, "DialogModel");

export { useDialogModel };
export default DialogModel;
