import React, { useContext } from "react";
import DialogModel from "../../models/dialogMoel";
import BaseDialog from "./containers/Base";
import ErrorDialog from "./containers/Error";

export default function Dialog() {
  const { list } = useContext(DialogModel.Context);

  return (
    <>
      {list.map((e) => {
        const { props } = e || {};
        const { type = "base" } = props || {};

        if (type === "error") {
          return <ErrorDialog key={e.id} data={e} />;
        }

        return <BaseDialog key={e.id} data={e} />;
      })}
    </>
  );
}

// toggleDialog({
//   open: true,
//   icon: Icon,
//   title: "您已成功註冊並成為Chaingate會員!",
//   text: "完成遊戲後\n即可解鎖優惠",
//   confirmButtonText: "馬上遊玩",
//   remark:
//     "*請使用手提電話/ 平版裝置進行此遊戲\n*請於安全的環境下進行此遊戲",
// });
