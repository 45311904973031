import styled from "styled-components";
import { devices } from "../../constants/breakpoints";

export const BodyContainer = styled.nav`
  //height: 100%;
  flex-grow: 1;
  margin-top: ${({ $headerHeight }) => `${$headerHeight}px`};
  // padding-bottom: ${({ $footerHeight }) => `${$footerHeight - 1}px`};
`;

export const HeaderContainer = styled.header`
  background-color: #f5f3fc;
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  width: 100%;
  z-index: 20;
  /* height: 50px; */
  border-bottom-right-radius: 20px;
  position: fixed;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  box-sizing: border-box;

  svg {
    fill: #fff;
  }

  @media ${devices.mobile} {
    border-bottom-left-radius: 20px;
    padding: 5px 40px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  position: relative;

  img {
    height: 50px;
    width: 50px;
  }

  @media ${devices.mobile} {
    img {
      width: 40px;
      height: 40px;
    }
  }
`;

export const MenuContainer = styled.div`
  z-index: 10;
`;

export const FooterContainer = styled.footer`
  padding: 40px 10px;
  background-color: ${({ theme }) => theme.primaryText};
  display: flex;
  align-items: center;
  flex-direction: column;
  // position: absolute;
  box-sizing: border-box;
  width: 100%;
  z-index: 10;
  bottom: 0px;

  @media ${devices.mobile} {
    padding: 20px 10px;
  }
`;

export const FooterTextRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

export const MemberButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  padding: 10px 60px;
  border-radius: 40px;
  font-size: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-left: 20px;

  img {
    height: 44px;
    margin-right: 10px;
  }

  @media ${devices.mobile} {
    margin-top: 20px;
  }
`;

export const BugerButton = styled.button`
  display: flex;
  background-color: transparent;

  path {
    stroke: ${({ theme }) => theme.primary};
    stroke-linecap: round;
  }

  @media ${devices.mobile} {
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;

const BugerBasicPath = styled.path`
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition:
    stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const BugerLine1 = styled(BugerBasicPath)`
  stroke-dasharray: ${({ open }) => (open ? "90 207" : "60 207")};
  stroke-dashoffset: ${({ open }) => open && " -134"};
  stroke-width: 8;
`;

export const BugerLine2 = styled(BugerBasicPath)`
  stroke-dasharray: ${({ open }) => (open ? "1 60" : "60 60")};
  stroke-dashoffset: ${({ open }) => open && " -30"};
  stroke-width: 8;
`;

export const BugerLine3 = styled(BugerBasicPath)`
  stroke-dasharray: ${({ open }) => (open ? "90 207" : "60 207")};
  stroke-dashoffset: ${({ open }) => open && " -134"};
  stroke-width: 8;
`;
