import styled from "styled-components";
import { devices } from "../constants/breakpoints";

export const StyledP = styled.p`
  color: ${(props) =>
    props.theme[props.color]
      ? `${props.theme[props.color]}`
      : props.theme.black};
  font-size: ${({ fontSize }) => fontSize && ` ${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${(props) => (props.$noMargin ? 0 : props.margin)};
  text-align: ${({ textAlign }) => textAlign};
  white-space: ${({ whiteSpace }) => whiteSpace};

  @media ${devices.mobile} {
    font-size: ${({ mobile }) => mobile?.fontSize && `${mobile?.fontSize}px`};
    margin: ${(props) =>
      props?.mobile?.$noMargin ? 0 : props?.mobile?.margin};
    text-align: ${({ mobile }) => mobile?.textAlign};
    font-family: ${({ mobile }) => mobile?.fontFamily};
  }
`;

export const StyledSpan = styled.span`
  color: ${(props) =>
    props.theme[props.color]
      ? `${props.theme[props.color]}`
      : props.theme.black};
  font-size: ${({ fontSize }) => fontSize && `${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};

  @media ${devices.mobile} {
    font-size: ${({ mobile }) => `${mobile?.fontSize}px`};
    margin: ${({ mobile }) => mobile?.margin};
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  align-items: ${({ alignItems }) => alignItems || "center"};
  justify-content: ${({ justifyContent }) => justifyContent};

  @media ${devices.mobile} {
    font-size: ${({ mobile }) => mobile?.fontSize};
    margin: ${({ mobile }) => mobile?.margin};
    padding: ${({ mobile }) => mobile?.padding};
    justify-content: ${({ mobile }) => mobile?.justifyContent};
    align-items: ${({ mobile }) => mobile?.alignItems};
    flex-direction: ${({ mobile }) => mobile?.flexDirection};
  }
`;

export const StorySectionP = styled.p`
  color: ${(props) =>
    props.theme[props.color]
      ? `${props.theme[props.color]}`
      : props.theme.black};
  font-size: ${({ fontSize }) => fontSize && ` ${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${(props) => (props.$noMargin ? 0 : props.margin)};
  text-align: ${({ textAlign }) => textAlign};
  white-space: ${({ whiteSpace }) => whiteSpace};

  @media ${devices.mobile} {
    font-size: ${({ mobile }) => mobile?.fontSize && `${mobile?.fontSize}px`};
    margin: ${(props) =>
      props?.mobile?.$noMargin ? 0 : props?.mobile?.margin};
    text-align: ${({ mobile }) => mobile?.textAlign};
  }
`;

export const StorySectionSetoFontP = styled.p`
  color: ${(props) =>
    props.theme[props.color]
      ? `${props.theme[props.color]}`
      : props.theme.black};
  font-size: ${({ fontSize }) => fontSize && ` ${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${(props) => (props.$noMargin ? 0 : props.margin)};
  text-align: ${({ textAlign }) => textAlign};
  white-space: ${({ whiteSpace }) => whiteSpace};

  @media ${devices.mobile} {
    font-size: ${({ mobile }) => mobile?.fontSize && `${mobile?.fontSize}px`};
    margin: ${(props) =>
      props?.mobile?.$noMargin ? 0 : props?.mobile?.margin};
    text-align: ${({ mobile }) => mobile?.textAlign};
    font-family: ${({ mobile }) => mobile?.fontFamily};
    line-height: ${({ mobile }) => mobile?.lineHeight};
  }
`;
