export const getMultiLangProperty = (item, lang) => {
  if (typeof item === "string") return item;
  if (!item) return "";
  if (typeof item !== "object") return "";
  if (item.hasOwnProperty(lang)) return item[lang];
  // if (item.hasOwnProperty("en")) return item.en;
  return "";
};

export function disableBodyScroll(disable) {
  if (disable) {
    document.body.classList.add("modal-open");
    return;
  }
  document.body.classList.remove("modal-open");
}
