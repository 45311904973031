import { useContext } from "react";
import BaseModel from "../base/BaseModel";
import BaseActions, { APIRequestGenerator } from "../base/BaseActions";
import APIHelper from "../base/APIHelper";
import UserModel from "./userModel";

const module = "merchantGame";

const useMerchantGameModel = (savedModelInstance = {}) => {
  const { token } = useContext(UserModel.Context);

  const { getOne, getList, updateOne, createOne } = BaseActions(module, {
    token,
  });

  async function startGame(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/mintsite/start`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // statue : initiated/playing
  async function updateGameStatus(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/MerchantGameRecord/${id}`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateGameResult(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/MerchantGameRecord/${id}/complete`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function drawReward(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/rewards/draw`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getOne,
    getList,
    updateOne,
    createOne,
    startGame,
    updateGameStatus,
    updateGameResult,
    drawReward,
  };
};

const MerchantGameModel = BaseModel(useMerchantGameModel, "MerchantGame");

export { useMerchantGameModel };
export default MerchantGameModel;
