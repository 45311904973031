import AppModel from "./appModel";
import DialogModel from "./dialogMoel";
import UserModel from "./userModel";
import CouponModel from "./cuponModel";
import ProductModel from "./productModel";
import MerchantGameModel from "./merchantGameModel";

const Models = [
  MerchantGameModel,
  ProductModel,
  CouponModel,
  UserModel,
  AppModel,
  DialogModel,
];

const BindModels = (ViewComponent) => {
  let bindedComponent = ViewComponent;
  Models.forEach((model) => {
    bindedComponent = model.withProvider(bindedComponent);
  });
  return bindedComponent;
};

export { BindModels };
export default Models;
