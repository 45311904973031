import React, { Suspense, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { BindModels } from "./models";
import "./App.css";
import Layout from "./components/Layout";
import ROUTES from "./constants/routes";
import NotFound from "./views/NotFound";
import AppModel from "./models/appModel";
import DeskTopVersionImage from "./assets/images/DeskTopVersion.png";

function App() {
  const { version } = useContext(AppModel.Context);

  if (version === "desktop") {
    return (
      <img
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        src={DeskTopVersionImage}
        alt="DeskTopVersion"
      />
    );
  }

  return (
    <Suspense>
      <Routes>
        <Route element={<Layout />}>
          {ROUTES.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <Suspense>
                  <Component />
                </Suspense>
              }
            />
          ))}
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default BindModels(App);
