import React from "react";
import { Container, Spinner } from "./style";

export default function Loader() {
  return (
    <Container>
      <Spinner />
    </Container>
  );
}
