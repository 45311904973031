import React from "react";
import { Container, LoadingImage } from "./style";
import gif from "../../assets/images/loading.gif";

export default function LoadingView() {
  return (
    <Container>
      <LoadingImage src={gif} alt="" />
    </Container>
  );
}
