export default {
  noRecord: "暫時沒有紀錄",

  menu: {
    ownership: "我的獎賞",
    about: "關於Chaingate",
    character: "角色介紹",
    nft: "NFT畫廊",
    story: "故事簡介",
    tnc: "條款及細則",
    privacyPolicy: "私隱政策",
  },
  home: { feature: "未來計劃", registerNow: "立即登記" },

  login: {
    title: "登入",
  },
  signUp: {
    username: "用戶名稱",
    title: "成為會員",
    register: "註冊",
    displayName: "暱稱",
    phone: "手機號碼",
    otpCode: "驗證碼",
    email: "電郵地址",
    password: "密碼",
    confirmPassword: "確認密碼",
    getOTP: "取得驗證碼",
    s: "秒",
    haveAccount: "我有帳號了",
  },
  forgetPassword: {
    title: "忘記密碼",
    input: "請輸入已登記之電郵作重置密碼之用",
  },
  resetPassword: {
    title: "重設密碼",
    request1: " - 至少包含8個字元",
    request2: "- 包含大寫,小寫及數字",
    request3: "- 包含至少一個特殊符號 ?!@#$%^&*",
    success: "密碼已重設，請重新登入",
  },

  error: {
    required: "必填",
    passowrdRequired:
      "密碼必須由至少八個英文字母和數字組成，包括一個大寫英文字母、一個小寫英文字母和一個數字",
    regSucess: "註册成功",
    confirmPasswordError: "密碼不相符",
    formatError: "請輸入正確",
    passwordNotMatch: "密碼不相符",
    unknow: "未知錯誤，請稍後再試",
    passwordError: "手機號碼或密碼錯誤，請輸入正確資料",
    currentPasswordError: "密碼不正確，請重新輸入",
  },
  button: {
    confirm: "確認",
    back: "返回",
    cancel: "取消",
    logout: "登出",
  },
  myRewards: {
    title: "我的獎賞",
    myNFT: "NFT",
    cupon: "會員優惠",
    expiredAt: "優惠期至",
  },
  cuponDetail: {
    title: "優惠券詳情",
    method: "使用方法",
    showQr: "於付款前出示此二維碼,即",
    getCupon: "可領取優惠",
  },
  nftDetail: {
    title: "NFT詳情",
  },
  tnc: {
    title: "條款及細則：",
    tnc1: "- Chaingate保留最終決定權。",
    tnc2: "- Chaingate保留權利可隨時修改本條款及細則、更改或終止獎賞活動而毋須預先通知。",
    tnc3: "- Chaingate現階段只提供網頁版試用版， Chaingate App將會於2023年第4季度推出。",
    tnc4: "- Chaingate會員得到NFT白名單資格後，請自行以加密貨幣錢包確認其NFT擁有權。",
    tnc5: "*只首5,000 名完成登記之會員，送完即止。",
  },
  game: {
    start: "遊戲玩法",
    rule: "遊戲開始後，\n請找出並點擊咖啡杯以獲取分數。",
    remark: "*請使用手提電話/ 平版裝置進行此遊戲\n*請於安全的環境下進行此遊戲",
    startGame: "開始遊戲",
  },
  profile: {
    title: "帳戶資料",
    changePassword: "更改密碼",
    newPassword: "新密碼",
    currentPassword: "舊密碼",
    changePasswordSuccess: "密碼已重設",
  },
};
