import CongratulationIcon from "../../../assets/images/congratulation.png";
import gaming from "../../../assets/images/gaming.png";
import giftOpen from "../../../assets/images/gift_open.png";
import Error from "../../../assets/images/Error.png";

export default function getImage(icon = "") {
  if (icon === "congrats") {
    return CongratulationIcon;
  }

  if (icon === "game") {
    return gaming;
  }

  if (icon === "gift") {
    return giftOpen;
  }

  if (icon === "error") {
    return Error;
  }

  return icon;
}
